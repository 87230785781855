<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-1">
        a) Which of the following statements is/are true for a neutral aqueous solution at
        <stemble-latex content="$25^o\text{C?}$" />
      </p>

      <div v-for="option in options" :key="`1-${option.value}`" class="mb-2 pl-8">
        <v-checkbox
          v-model="inputs.input1"
          class="d-inline-block my-0 py-0"
          :value="option.value"
          :dense="true"
          hide-details="true"
          :ripple="false"
        >
          <template #label>
            <stemble-latex :content="option.condition" />
          </template>
        </v-checkbox>
      </div>

      <p class="mb-1 mt-6">
        b) Which of the following statements is/are true for a neutral aqueous solution regardless
        of the temperature?
      </p>

      <div v-for="option in options" :key="`2-${option.value}`" class="mb-2 pl-8">
        <v-checkbox
          v-model="inputs.input2"
          class="d-inline-block my-0 py-0"
          :value="option.value"
          :dense="true"
          hide-details="true"
          :ripple="false"
        >
          <template #label>
            <stemble-latex :content="option.condition" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question136',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: [],
      },
      options: [
        {condition: '$\\text{pH}\\,=\\,7.00$', value: '1'},
        {condition: '$\\text{pH}\\,=\\,14.00$', value: '2'},
        {condition: '$\\text{pOH}\\,=\\,7.00$', value: '3'},
        {condition: '$\\text{pOH}\\,=\\,14.00$', value: '4'},
        {condition: '$\\ce{[H3O+] = [OH-]}$', value: '5'},
      ],
    };
  },
};
</script>
